<template>
  <div class="box">
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <div
          @click="$router.back(-1)"
          style="color: #42a16a; cursor: pointer"
          v-show="!edit"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
        <span style="color: #42a16a;" @click="edit = false" v-show="edit">
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>
      <div class="headerTitle">
        <span>{{ $t("flashCards.My Vocab Lists") }}</span>
      </div>
      <div class="pr-3 edit">
        <div class="pointer" @click="edit = !edit">
          <span
            class="text-success"
            style="display:inline-block;width:20px;font-size:24px"
            v-show="!edit"
          >
            <i class="fa fa-edit"></i>
          </span>
          <span
            class="text-danger"
            style="display:inline-block;width:20px;font-size:24px"
            v-show="edit"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="container content">
      <div v-show="!edit">
        <el-divider content-position="left"
          ><i class="fas fa-book book"></i>
          {{ $t("flashCards.Type") }}</el-divider
        >
        <div class="row" style="margin:0">
          <div class="col-6 type-item">
            <div
              :class="startType === 2 ? 'list list-select' : 'list'"
              @click="changeType(2)"
            >
              <div class="list-title">{{ $t("flashCards.Flash Cards") }}</div>
              <div style="font-size:28px">
                <i class="fas fa-copy"></i>
              </div>
            </div>
          </div>
          <div class="col-6 type-item">
            <div
              :class="startType === 1 ? 'list list-select' : 'list'"
              @click="changeType(1)"
            >
              <div class="list-title">{{ $t("flashCards.Word List") }}</div>
              <div style="font-size:28px">
                <i class="fas fa-list"></i>
              </div>
            </div>
          </div>
        </div>
        <el-divider content-position="left"
          ><i class="fas fa-list"></i>
          {{ $t("flashCards.Select List(s)") }}</el-divider
        >
      </div>
      <div class="row" v-show="!edit">
        <!-- <router-link
          :class="
            list.order === 0
              ? 'col-12 content-item default'
              : 'col-6 content-item'
          "
          :to="{
            name: 'StartList',
            query: {
              id: list.id
            }
          }"
          v-for="(list, index) in lists"
          :key="index"
        >
          <div class="list">
            <div class="list-title">{{ list.title }}</div>
            <div class="list-description">
              {{ list.intro }}
            </div>
          </div>
        </router-link> -->
        <div
          :class="
            list.order === 0
              ? 'col-12 content-item default'
              : 'col-6 content-item'
          "
          v-for="(list, index) in lists"
          :key="index"
        >
          <div :class="`block${list.id} list`" @click="toggleSelect(list.id)">
            <div class="list-title">{{ list.title }}</div>
            <div class="list-description">
              {{ list.intro }}
            </div>
          </div>
        </div>
        <div class="col-6 content-item" @click="showNewList">
          <div class="add-button text-center">
            <span><i class="el-icon-plus avatar-uploader-icon"></i></span>
          </div>
        </div>
      </div>
      <div v-show="edit">
        <div v-for="(list, index) in lists" :key="index">
          <div class="wordCard">
            <div class="word" style="max-width:200px;overflow:hidden;">
              <span>{{ list.title }}</span>
              <div>
                <small>{{ list.intro }}</small>
              </div>
            </div>
            <div class="voice">
              <el-button
                type="primary"
                size="small"
                @click="showEditList(list)"
              >
                <i class="fa fa-edit"></i>
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="deleteList(list.id, index)"
              >
                <i class="fa fa-trash-alt"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="levelButtons pointer" @click="startLists">
      <div class="d-flex flex-buttons">
        <div style="width: 100%; color: #42a16a;" class="btnStyle">
          <div style="background-color: #42a16a;" class="btnBar"></div>
          <div><i class="fas fa-play-circle"></i></div>
          <div class="btnClick">START</div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="edit ? '編輯' : '新增'"
      :visible.sync="dialogVisible"
      width="92%"
    >
      <div>
        <div class="demo-input-suffix" style="margin-bottom:10px">
          <h6>Title:</h6>
          <el-input placeholder="Title" v-model="activeList.title"> </el-input>
        </div>
        <div class="demo-input-suffix">
          <h6>Description:</h6>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="Description"
            v-model="activeList.intro"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="createList" v-if="!edit"
          >确 定</el-button
        >
        <el-button type="primary" @click="saveList" v-if="edit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import VocabList from "@/apis/myVocabLists";

export default {
  metaInfo() {
    return {
      title: "My Vocab List - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      startType: 1,
      edit: false,
      dialogVisible: false,
      activeList: {
        id: 0,
        title: "",
        intro: ""
      },
      lists: [],
      selectedList: []
    };
  },
  computed: {},
  mounted() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    this.getLists();
  },
  beforeDestroy() {
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
  },
  watch: {},
  methods: {
    changeType(type) {
      this.startType = type;
    },
    async createList() {
      if (this.activeList.title !== "") {
        await VocabList.createVocabList({
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    },
    async saveList() {
      if (this.activeList.title !== "") {
        await VocabList.saveVocabList(this.activeList.id, {
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    },
    async deleteList(id, index) {
      await this.$confirm("確認刪除？", "提示", {
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(_ => {
        VocabList.deleteVocabList(id);
        this.lists.splice(index, 1);
        this.$notify({
          message: "已刪除",
          position: "bottom-right"
        });
      });
    },
    async getLists() {
      const res = await VocabList.getVocabLists();
      this.lists = res.vocab_collects;
    },
    showNewList() {
      this.dialogVisible = true;
      this.activeList = {
        id: 0,
        title: "",
        intro: ""
      };
    },
    showEditList(list) {
      this.activeList = list;
      this.dialogVisible = true;
    },
    toggleSelect(id) {
      // 獲取目前 block class name
      const currentClassName = "block" + id.toString();
      let ele = document.getElementsByClassName(currentClassName)[0];
      // 如果已經選擇此 block，則將他從 array 移除，並將背景設為透明
      if (this.selectedList.includes(id)) {
        this.selectedList = this.selectedList.filter(e => e !== id);
        ele.style.backgroundColor = "#efefef";
        ele.style.color = "black";
        ele.style.border = "1px dashed #ccc";
      } else {
        // 如果還沒選擇此 block，則將他放入 array，並將背景設為淺綠色
        this.selectedList.push(id);

        ele.style.backgroundColor = "#42a16a";
        ele.style.color = "white";
        ele.style.border = "1px solid #42a16a";
      }
    },
    async startLists() {
      if (this.selectedList.length > 0) {
        if (this.startType === 2) {
          this.$router.push({
            name: "MemorizeList",
            params: {
              title: "My Vocab Lists"
            },
            query: {
              from: "my",
              selectedList: this.selectedList.join(",")
            }
          });
        } else {
          this.$router.push({
            name: "FlashCardsList",
            params: {
              title: "My Vocab Lists"
            },
            query: {
              from: "my",
              selectedList: this.selectedList.join(",")
            }
          });
        }
      } else {
        this.$message({
          message: "請選擇至少一個收藏夾！",
          type: "error"
        });
      }
    }
  }
};
</script>

<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 95%;
  }
}
</style>
<style scoped>
.box {
  height: 100vh;
  display: grid;
  grid-template-rows: 55px auto 83px;
}
.row {
  margin: 0;
}
.content {
  overflow: auto;
  padding-left: 8px;
  padding-right: 8px;
}
.content-item {
  padding-left: 8px;
  padding-right: 8px;
}
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
}
.return:hover {
  opacity: 60%;
}
/* .content {
  padding-top: 15px;
} */
.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  cursor: pointer;
  height: 140px;
  padding: 10px;
  border: 1px dashed #ccc;
  background: #efefef;
  /* color: #212529; */
}
.default .list {
  background: orange;
  border: 1px solid orange;
}
.list-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
}
.list-description {
  float: left;
  height: 90px;
  overflow: hidden;
  font-size: 14px;
  word-break: break-word !important;
}
.add-button {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  height: 140px;
}
.content-item,
.type-item {
  margin-bottom: 15px;
}
.type-item .list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
  height: auto;
  background: white;
  border: 1px solid #d9d9d9;
  text-align: center;
}
.type-item .list-title {
  font-size: 1rem;
  /* color: #212529; */
}
.type-item .list-select .list-title {
  color: white;
}
.type-item .list-select {
  color: white;
  background: #42a16a;
  border: 1px solid #42a16a;
}
.add-button span {
  font-size: 28px;
  line-height: 140px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}

.wordCard {
  border-bottom: 1px solid #d7dae2;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
}
.wordCard.addNew {
  font-size: 14px;
  font-weight: 500;
}
.wordCard .word {
  color: #42a16a;
  font-size: 16px;
  font-weight: 500;
}
.levelButtons {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
}

.btnClick {
  font-size: 18px;
  line-height: 20px;
  padding-top: 0;
  cursor: pointer;
}
::v-deep .el-divider__text.is-left {
  left: 0;
}
::v-deep .el-divider__text {
  padding-left: 8px;
  color: #42a16a;
}
.voice {
  text-align: right;
  margin: auto 0;
}
::v-deep .el-divider {
  margin-bottom: 32px;
}
::v-deep .el-divider__text.is-left {
  left: 0;
}
::v-deep .el-divider__text {
  font-size: 20px;
  padding-left: 8px;
  color: #42a16a;
}
</style>
